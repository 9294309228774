<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <b-img
          :src="appLogoImage"
          alt="logo"
        />
        <h2 class="brand-text text-primary ml-1">
          {{ appName }}
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            {{ greeting }} <br>
          </b-card-title>
          <b-card-text class="mb-2">
            Go go go Miniapoly! Kita pasti bisa!
          </b-card-text>

          <b-button
            :disabled="isLoadingGoogle"
            block
            variant="light px-0"
            @click="handleLoginGoogle"
          >
            <b-img
              :src="require('@/assets/images/icons/google-icon.svg')"
              alt="Google Icon Miniapoly Login"
              class="mr-50"
            />
            <b-spinner
              v-if="isLoadingGoogle"
              class="mr-50"
              small
            />
            <span v-if="!isLoadingGoogle">Masuk dengan Google</span>
          </b-button>

          <hr>
          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent
            >
              <!-- email -->
              <b-form-group
                label="Email"
                label-for="login-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false:null"
                    name="login-email"
                    autocomplete="username"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- Password -->
              <b-form-group
                label="Password"
                label-for="login-password"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      autocomplete="current-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <b-form-group v-if="false">
                <b-form-checkbox
                  id="remember-me"
                  v-model="status"
                  name="checkbox-1"
                >
                  Remember Me
                </b-form-checkbox>
              </b-form-group>

              <hr>

              <!-- Submit button -->
              <b-button
                :disabled="loadingSubmit"
                type="submit"
                variant="primary"
                block
                @click="validationForm"
              >
                <b-spinner
                  v-if="loadingSubmit"
                  class="mr-50"
                  small
                />
                Masuk
              </b-button>
            </b-form>
          </validation-observer>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BSpinner,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import { $themeConfig } from '@themeConfig'
// TODO: fix all no-cycle
// eslint-disable-next-line import/no-cycle
import useJwt from '@/auth/jwt/useJwt'
import { adminAbility } from '@/libs/acl/config'
// eslint-disable-next-line import/no-cycle
import { getHomeRouteForLoggedInUser } from '@/auth/utils'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      greeting: '',

      status: '',
      password: '',
      userEmail: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),

      loadingSubmit: false,
      isLoadingGoogle: false,
      isSignIn: false,
      // validation rules
      required,
      email,
    }
  },
  setup() {
    // App Name
    const { appName, appLogoImage } = $themeConfig.app

    return {
      // App Name
      appName,
      appLogoImage,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  mounted() {
    this.setGreeting()
  },
  methods: {
    setGreeting() {
      const currentTime = new Date().getHours()
      if (currentTime >= 4 && currentTime < 11) {
        this.greeting = 'Selamat Pagi!'
      } else if (currentTime < 15) {
        this.greeting = 'Selamat Siang!'
      } else if (currentTime < 18) {
        this.greeting = 'Selamat Sore!'
      } else {
        this.greeting = 'Selamat Malam!'
      }
    },
    validationForm() {
      this.$refs.loginValidation.validate().then(async success => {
        if (success) {
          const body = {
            email: this.userEmail,
            password: this.password,
          }

          this.loadingSubmit = true

          // authenticate user
          const { data: authData } = await useJwt.login(body)
            .finally(() => {
              setTimeout(() => {
                this.loadingSubmit = false
              }, 100)
            })

          const { token } = authData

          // save the access token to the localstorage
          useJwt.setToken(token)

          // get current active user data
          const { data: userData } = await useJwt.user()

          // get user roles
          const roles = userData.data.roles.filter(item => item !== 'customer')

          // assign abilities or permissions based on user roles
          let ability = []
          if (roles.includes('admin')) {
            ability = [...adminAbility]
          }

          // apply abilities
          this.$ability.update(ability)

          // put the ability data into the user data
          // because acl/ability.js authorize based on the user.ability
          const updatedUserData = { ...userData.data, ability }
          // save the user data to vuex
          await this.$store.commit('auth/UPDATE_USER_DATA', updatedUserData)

          await this.$router.replace(getHomeRouteForLoggedInUser(roles))
        }
      })
    },
    // eslint-disable-next-line consistent-return
    async handleLoginGoogle() {
      try {
        this.isLoadingGoogle = true
        // Sign in the user using Google Authentication
        const googleUser = await this.$gAuth.signIn()

        // Prepare the body of the request, which includes the token from Google
        const body = {
          token: googleUser.getAuthResponse().id_token,
        }

        // Send a login request to your server with the token from Google
        const {
          data: {
            data: { token },
          },
        } = await useJwt.loginGoogle(body)

        // Save the JWT token received from your server to local storage
        useJwt.setToken(token)

        // get current active user data
        const { data: userData } = await useJwt.user()

        // get user roles
        const roles = userData.data.roles.filter(item => item !== 'customer')

        // assign abilities or permissions based on user roles
        let ability = []
        if (roles.includes('admin')) {
          ability = [...adminAbility]
        }

        // apply abilities
        this.$ability.update(ability)

        // put the ability data into the user data
        // because acl/ability.js authorize based on the user.ability
        const updatedUserData = { ...userData.data, ability }
        // save the user data to vuex
        await this.$store.commit('auth/UPDATE_USER_DATA', updatedUserData)

        // Redirect the user to their home page based on their roles
        await this.$router.replace(getHomeRouteForLoggedInUser(roles))
      } catch (error) {
        this.isLoadingGoogle = false
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
